<template>
  <div>
    <v-card
      v-if="pSelectedHost"
      :class="drawer ? 'drawer-open' : 'drawer-closed'"
    >
      <v-data-table
        class="team-display"
        :headers="headers"
        :items="displayTeams"
        :loading="currentPageLoading"
        :server-items-length="totalCount"
        @update:options="paginate($event)"
        :footer-props="footerProps"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Teams</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="canUserCreateTeam"
              color="primary"
              class="team-add-btn"
              @click="openEditTeamDialog({})"
            >
              New Team
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <router-link
            :to="`/teams/${item.id}?tab=team-info`"
            class="router-link-active"
          >
            {{ item.name }}
          </router-link>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <TeamStatus
            :pSelectedTeam="item"
            @reloadData="reloadPAge"
            :pLoading="currentPageLoading"
          />
        </template>

        <template v-slot:[`item.managerApprovalState`]="{ item }">
          <CustomTeamManagerState :pSelectedTeam="item" />
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ moment(item.createdAt).format(dateTimeMomentFormat) }}
        </template>

        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ moment(item.updatedAt).format(dateTimeMomentFormat) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-icon v-if="item.status === TEAM_DRAFT_STATUS"
              medium
              class="team-delete-btn"
              color="red"
              :disabled="updating || currentPageLoading"
              @click="openDeleteTeamDialog(item)"
            >mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <TeamDialog ref="editTeamDialog" />
    <ConfirmationDialog ref="deleteDialog" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import { Team } from "../../model/team.model";
import {
  paginateTable,
  TEAM_ACTIVE_STATUS,
  TEAM_DRAFT_STATUS,
  TEAM_PAUSED_STATUS,
  dateTimeMomentFormat, logAndExtractMessage, dismissAction,
} from "../../utils/utils";

export default {
  name: "Teams",

  components: {
    TeamDialog: () => import("../dialogs/TeamDialog.vue"),
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
    TeamStatus: () => import("../custom-components/TeamStatusComponent.vue"),
    CustomTeamManagerState: () => import("../custom-components/TeamManagerStateComponent.vue"),
  },

  props: {
    pSelectedHost: Object,
  },

  data: () => ({
    moment,
    dateTimeMomentFormat,

    TEAM_ACTIVE_STATUS,
    TEAM_DRAFT_STATUS,
    TEAM_PAUSED_STATUS,

    Team,
    localStorageTableSizeKey: "teams.table.size",

    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      { text: "Name", value: "name", sortable: false },
      { text: "Status", value: "status", sortable: false },
      {
        text: "Manager email",
        value: "managerEmail",
        sortable: false,
      },
      {
        text: "Manager Approval State",
        value: "managerApprovalState",
        sortable: false,
      },
      { text: "Created at ", value: "createdAt", sortable: false },
      { text: "Updated at", value: "updatedAt", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("teamsModule", [
      "currentPageLoading",
      "currentPage",
      "displayTeams",
      "totalCount",
      "updating",
    ]),
    ...mapState("coreModule", ["drawer"]),
    ...mapState("userModule", ["user"]),

    canUserCreateTeam() {
      return this.user.isSuperAdmin
        || this.user.isUserAdminHost(this.pSelectedHost)
        || (this.user.isStandard && this.user.viewerUsersCount === 1);
    },
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        hostId: this.pSelectedHost.id,
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("teamsModule/loadPage", currentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openEditTeamDialog(team) {
      const sentTeam = Object.keys(team).length
        ? team
        : { host: this.pSelectedHost };

      this.$refs.editTeamDialog.openDialog(sentTeam);
    },

    openActivateTeamDialog(team) {
      this.$refs.deleteDialog.openDialog({
        header: "Activate confirmation",
        text: `Are you sure you want to activate "${team.name}"?`,
        submitText: "Activate",
        submitColor: "green",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("teamsModule/activateTeam", team);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },

    async reloadPAge() {
      try {
        await this.$store.dispatch("teamsModule/reloadPage");
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openDeactivateTeamDialog(team) {
      this.$refs.deleteDialog.openDialog({
        header: "Deactivate confirmation",
        text: `Are you sure you want to deactivate "${team.name}"?`,
        submitText: "Deactivate",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("teamsModule/deactivateTeam", team);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },

    openDeleteTeamDialog(team) {
      this.$refs.deleteDialog.openDialog({
        header: "Delete confirmation",
        text: `Are you sure you want to delete "${team.name}" team?`,
        submitText: "Delete",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("teamsModule/deleteTeam", team);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },
  },

  mounted() {
    this.$emit("onMount");
  },
};
</script>
